import React from 'react'
import {
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect
      selector={[
        dispatch.restaurant.getAddress,
        dispatch.restaurant.getFormattedPhoneNumber,
        dispatch.restaurant.getEmail,
      ]}
    >
      {([address, phoneNumber, email, locationId]) => (
        <CFView>
          <MobileScreen>
            <CFView column center mb="20px">
              <CFView h3 futura bold color="#DE0009" mb="20px">
                CONTACT US
              </CFView>
              <CFView column center>
                {address && (
                  <CFView row w="100%" mb="1rem">
                    <CFView w="9rem">
                      <CFView heading h5 white bold>
                        Address
                      </CFView>
                    </CFView>
                    <CFLink href="https://goo.gl/maps/fCpPc5KH8YQ8w3F2A">
                      <CFView heading h5 white bold maxWidth="200px">
                        {address}
                      </CFView>
                    </CFLink>
                  </CFView>
                )}
                {phoneNumber && (
                  <CFView row w="100%" mb="10px">
                    <CFView w="9rem">
                      <CFView heading h5 white bold>
                        Phone
                      </CFView>
                    </CFView>
                    <CFView heading h5 white bold>
                      {phoneNumber}
                    </CFView>
                  </CFView>
                )}
                {email && (
                  <CFView row w="100%">
                    <CFView w="9rem">
                      <CFView heading h5 white bold>
                        Email
                      </CFView>
                    </CFView>
                    <CFView heading h5 white bold>
                      {email}
                    </CFView>
                  </CFView>
                )}
              </CFView>
            </CFView>
          </MobileScreen>
          <DefaultScreen>
            <CFView mr="15px">
              <CFView h3 futura bold color="#DE0009" pb="20px">
                CONTACT US
              </CFView>
              <CFView column center>
                {address && (
                  <CFView row w="100%" mb="1rem">
                    <CFView w="9rem">
                      <CFView heading h5 white bold>
                        Address
                      </CFView>
                    </CFView>
                    <CFLink href="https://goo.gl/maps/fCpPc5KH8YQ8w3F2A">
                      <CFView heading h5 white bold maxWidth="210px">
                        {address}
                      </CFView>
                    </CFLink>
                  </CFView>
                )}
                {phoneNumber && (
                  <CFView row w="100%" mb="10px">
                    <CFView w="9rem">
                      <CFView heading h5 white bold>
                        Phone
                      </CFView>
                    </CFView>
                    <CFView heading h5 white bold>
                      {phoneNumber}
                    </CFView>
                  </CFView>
                )}
                {email && (
                  <CFView row w="100%" mb="10px">
                    <CFView w="9rem">
                      <CFView heading h5 white bold>
                        Email
                      </CFView>
                    </CFView>
                    <CFLink href={`mailto:${email}`}>
                      <CFView heading h5 white bold>
                        {email}
                      </CFView>
                    </CFLink>
                  </CFView>
                )}
              </CFView>
            </CFView>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
